import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import HelpIcon from "@mui/icons-material/Help";
import skillSummary from "../asset/other/skill_summary.png";
import weaponSummary from "../asset/other/weapon_summary.png";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 220,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function HowToCalcModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <HelpIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            계산에 사용된 데이터
          </Typography>
          <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTiAN0_E-IdKHUQYJ5EUrMD7h7Vb08J1xCYNJGmIhxXus98YBjKTP-Xb8Ljoyc3bQ7WhrcROVorcWjY/pubhtml#">
            원본링크
          </a>
          <img src={skillSummary} alt="skillSummary" width="180px" />
          <img src={weaponSummary} alt="weaponSummary" width="180px" />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            목표대비 소지량 계산방법
          </Typography>
          <Typography id="modal-modal-title" variant="subtitle2" component="h2">
            ex) 은색책 5개 목표, 현재 똥색책 3개 은색책1개 보유시 아니다 됐다
            그냥 니들이 생각한 바로 그방법으로 계산했음
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
