import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...(props.value >= 100 ? 100 : props)}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface ProgressBarProps {
  process: number;
}

export default function ProgressBar({ process }: ProgressBarProps) {
  //   const [progress, setProgress] = React.useState();

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={process} />
    </Box>
  );
}
