import TopMenu from "./components/TopMenu";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const appStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
  },
  header: {
    display: "flex",
    justifyContent: "center",
  },
});

function App() {
  const classes = appStyles();

  return (
    <div className={classes.root}>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        className={classes.header}
      >
        원붕이도 쓸수있는 소재 계산기
      </Typography>
      <TopMenu />
    </div>
  );
}

export default App;
