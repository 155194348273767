import { TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChangeEventHandler, useEffect, useState } from "react";
import GradeSelector from "./GradeSelector";
import InputCurrentItems from "./InputCurrentItems";
import {
  CurrentItemValue,
  CalculateResult,
  calculation,
} from "../utils/calculation";
import ProgressBar from "./ProgressBar";
import fragileResin from "../asset/other/Fragile_Resin.png";
import HowToCalcModal from "./HowToCalcModal";

interface CalculatorProps {
  type: "WEAPON" | "SKILL";
}

export const calculatorStyles = makeStyles<Theme, CalculatorProps>(
  (theme: Theme) => ({
    root: {},
    inputContainer: {
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
      display: "grid",
      gridTemplateColumns: "repeat(2, 10rem)",
      gridTemplateRows: (props) =>
        props.type === "SKILL"
          ? "3rem 8rem 3rem 12rem"
          : "3rem 8rem 3rem 16rem",
      gridTemplateAreas: (props) =>
        props.type === "SKILL"
          ? `
    "targetHeader targetHeader"
    "targetGrade targetCounts"
    "currentHeader currentHeader"
    "inputField inputField"
    `
          : `
    "targetHeader targetHeader"
    "targetGrade targetCounts"
    "currentHeader currentHeader"
    "inputField inputField"
    `,
    },
    targetHeader: {
      gridArea: "targetHeader",
    },
    targetGrade: {
      gridArea: "targetGrade",
    },
    targetCounts: {
      gridArea: "targetCounts",
    },
    currentHeader: {
      gridArea: "currentHeader",
      display: "flex",
      alignItems: "center",
    },
    inputField: {
      gridArea: "inputField",
    },

    outputContainer: {},
    inputBox: {
      fontSize: "0.875rem",
      // minHeight: "100px",
      boxSizing: "border-box",
      minWidth: "100px",
      borderRadius: "0.75em",
      margin: "0.5em",
      padding: "10px",
      lineHeight: 1.5,
    },
    outPutResinHeader: {
      display: "flex",
      alignItems: "center",
    },
    outPutResin: {
      display: "flex",
      fontSize: "1.5rem",
      alignItems: "center",
    },
  })
);

export default function Calculator({ type }: CalculatorProps) {
  const calculatorType: CalculatorProps = { type };
  const classes = calculatorStyles(calculatorType);

  const [wannaGrade, setWannaGrade] = useState<string>("");
  const [wannaCounts, setWannaCounts] = useState<number | undefined>(undefined);
  const handleWannaCounts = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value === "") {
      setWannaCounts(undefined);
      return;
    }
    setWannaCounts(Number(evt.target.value));
  };

  const [currentItems, setCurrentItems] = useState<CurrentItemValue>({
    BRONZE: 0,
    BLACK: 0,
    GOLD: 0,
    SILVER: 0,
  });

  const [calcResult, setCalcResult] = useState<CalculateResult>(
    calculation({
      type: type,
      wannaCounts,
      wannaGrade,
      currentItems,
    })
  );

  useEffect(() => {
    setCalcResult(
      calculation({
        type: type,
        wannaCounts,
        wannaGrade,
        currentItems,
      })
    );
  }, [wannaGrade, wannaCounts, currentItems]);

  return (
    <div className={classes.root}>
      <div className={classes.inputContainer}>
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className={classes.targetHeader}
        >
          목표 아이템
        </Typography>
        <div className={classes.targetGrade}>
          <label htmlFor="">목표 소재 등급</label>
          <GradeSelector type={type} setGrade={setWannaGrade} />
        </div>
        <div className={classes.targetCounts}>
          <label htmlFor="">목표 소재 갯수</label>
          <TextField
            className={classes.inputBox}
            id="outlined-basic"
            label="갯수"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              margin: "0.5em",
            }}
            variant="outlined"
            placeholder={"ex) 12"}
            value={wannaCounts}
            onChange={handleWannaCounts}
          />
        </div>
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className={classes.currentHeader}
        >
          소지중인 아이템
        </Typography>
        <div className={classes.inputField}>
          <InputCurrentItems
            type={type}
            currentItems={currentItems}
            setCurrentItems={setCurrentItems}
          />
        </div>
      </div>

      <div className={classes.outputContainer}>
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className={classes.currentHeader}
        >
          계산결과
          <HowToCalcModal />
        </Typography>
        <div>목표대비 소지량</div>
        <ProgressBar process={calcResult.nowPercent} />
        <div className={classes.outPutResinHeader}>
          목표까지 필요한 예상 레진수
        </div>
        <div className={classes.outPutResin}>
          <img src={fragileResin} alt="Resin" width="40px" />
          {calcResult.needResin}
        </div>
      </div>
    </div>
  );
}
