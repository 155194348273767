import * as React from "react";
import SelectUnstyled, {
  SelectUnstyledProps,
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import { SkillGradeImg, WeaponGradeImg } from "./imageType";

const StyledButton = styled("button")`
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 100px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: #000;

  &.${selectUnstyledClasses.focusVisible} {
    outline: 4px solid rgba(100, 100, 100, 0.3);
  }

  &.${selectUnstyledClasses.expanded} {
    border-radius: 0.75em 0.75em 0 0;

    &::after {
      content: "▴";
    }
  }

  &::after {
    content: "▾";
    float: right;
  }
`;

const StyledListbox = styled("ul")`
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: #fff;
  min-width: 100px;
  border: 1px solid #ccc;
  border-top: none;
  color: #000;
`;

const StyledOption = styled(OptionUnstyled)`
  list-style: none;
  padding: 4px 10px;
  margin: 0;
  border-bottom: 1px solid #ddd;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.disabled} {
    color: #888;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: rgba(25, 118, 210, 0.08);
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: #16d;
    color: #fff;
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #05e;
    color: #fff;
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: #39e;
  }
`;

const StyledPopper = styled(PopperUnstyled)`
  z-index: 2;
`;

const CustomSelect = React.forwardRef(function CustomSelect<TValue>(
  props: SelectUnstyledProps<TValue>,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  const components: SelectUnstyledProps<TValue>["components"] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
}) as <TValue>(
  props: SelectUnstyledProps<TValue> & React.RefAttributes<HTMLUListElement>
) => JSX.Element;

interface GradeSelectorProps {
  type: "SKILL" | "WEAPON";
  setGrade: React.Dispatch<React.SetStateAction<string>>;
}

export default function GradeSelector({ type, setGrade }: GradeSelectorProps) {
  const handleChange = (value: string | null) => {
    if (value === null) return;
    setGrade(value);
  };

  const skillList = ["BRONZE", "SILVER", "GOLD"];
  const weaponList = ["BRONZE", "BLACK", "SILVER", "GOLD"];

  React.useEffect(() => {
    handleChange("BRONZE");
  }, []);

  switch (type) {
    case "SKILL":
      return (
        <CustomSelect defaultValue={"BRONZE"} onChange={handleChange}>
          {skillList.map((grade, idx) => {
            return (
              <StyledOption value={grade} key={grade + String(idx)}>
                <img width="40px" src={SkillGradeImg.get(grade)} alt="BRONZE" />
                {grade}
              </StyledOption>
            );
          })}
        </CustomSelect>
      );

    case "WEAPON":
      return (
        <CustomSelect defaultValue={"BRONZE"} onChange={handleChange}>
          {weaponList.map((grade, idx) => {
            return (
              <StyledOption value={grade} key={grade + String(idx)}>
                <img
                  width="40px"
                  src={WeaponGradeImg.get(grade)}
                  alt="BRONZE"
                />
                {grade}
              </StyledOption>
            );
          })}
        </CustomSelect>
      );

    default:
      return <div>ERROR</div>;
  }
}
