import weaponBronze from "../asset/weapon/weapon_bronze.png";
import weaponBlack from "../asset/weapon/weapon_black.png";
import weaponSilver from "../asset/weapon/weapon_silver.png";
import weaponGold from "../asset/weapon/weapon_gold.png";

import skillBronze from "../asset/skill/skill_bronze.png";
import skillSliver from "../asset/skill/skill_silver.png";
import skillGold from "../asset/skill/skill_gold.png";

export const WeaponGradeImg = new Map();
WeaponGradeImg.set("BRONZE", weaponBronze);
WeaponGradeImg.set("BLACK", weaponBlack);
WeaponGradeImg.set("SILVER", weaponSilver);
WeaponGradeImg.set("GOLD", weaponGold);

export const SkillGradeImg = new Map();
SkillGradeImg.set("BRONZE", skillBronze);
SkillGradeImg.set("SILVER", skillSliver);
SkillGradeImg.set("GOLD", skillGold);
