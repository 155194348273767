import { TextField } from "@mui/material";
import { useEffect } from "react";
import { CurrentItemValue } from "../utils/calculation";
import { calculatorStyles } from "./Calculator";
import { SkillGradeImg, WeaponGradeImg } from "./imageType";

export interface InputCurrentItemsProps {
  type: "WEAPON" | "SKILL";
  currentItems: CurrentItemValue;
  setCurrentItems: React.Dispatch<React.SetStateAction<CurrentItemValue>>;
}

export default function InputCurrentItems({
  type,
  currentItems,
  setCurrentItems,
}: InputCurrentItemsProps) {
  const inputType: Pick<InputCurrentItemsProps, "type"> = { type };
  const classes = calculatorStyles(inputType);

  const handleInputCounts = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setCurrentItems({
      ...currentItems,
      [name]: Number(value),
    });
  };

  switch (type) {
    case "SKILL":
      const skillItemList = [
        {
          grade: "BRONZE",
        },
        {
          grade: "SILVER",
        },
        {
          grade: "GOLD",
        },
      ];
      return (
        <>
          {skillItemList.map((item, idx) => {
            return (
              <div key={item + String(idx)}>
                <label htmlFor="">
                  <img
                    src={SkillGradeImg.get(item.grade)}
                    alt={item.grade}
                    width="50px"
                  />
                </label>
                <TextField
                  id="outlined-basic"
                  label={item.grade}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    margin: "0.5em",
                  }}
                  variant="outlined"
                  size="small"
                  name={item.grade}
                  placeholder={idx === 0 ? "ex) 3" : ""}
                  onChange={handleInputCounts}
                />
              </div>
            );
          })}
        </>
      );

    case "WEAPON":
      const weaponItemList = [
        {
          grade: "BRONZE",
        },
        {
          grade: "BLACK",
        },
        {
          grade: "SILVER",
        },
        {
          grade: "GOLD",
        },
      ];
      return (
        <>
          {weaponItemList.map((item, idx) => {
            return (
              <div key={item + String(idx)}>
                <label htmlFor="">
                  <img
                    src={WeaponGradeImg.get(item.grade)}
                    alt={item.grade}
                    width="50px"
                  />
                </label>
                <TextField
                  id="outlined-basic"
                  label={item.grade}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    margin: "0.5em",
                  }}
                  variant="outlined"
                  size="small"
                  name={item.grade}
                  placeholder={idx === 0 ? "ex) 5" : ""}
                  onChange={handleInputCounts}
                />
              </div>
            );
          })}
        </>
      );

    default:
      return (
        <>
          <div>오류</div>
        </>
      );
  }
}
