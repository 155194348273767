import { InputCurrentItemsProps } from "../components/InputCurrentItems";

export interface CurrentItemValue {
  BRONZE: number;
  BLACK: number;
  SILVER: number;
  GOLD: number;
}

interface CalculationProps extends Pick<InputCurrentItemsProps, "type"> {
  wannaGrade: string;
  wannaCounts?: number;
  currentItems: CurrentItemValue;
}

export interface CalculateResult {
  nowPercent: number;
  needResin: number;
}

const setGradeValue = ({
  type,
  wannaGrade,
}: Pick<CalculationProps, "type" | "wannaGrade">): number => {
  if (type === "SKILL") {
    switch (wannaGrade) {
      case "BRONZE":
        return 1;
      case "SILVER":
        return 3;
      case "GOLD":
        return 9;

      default:
        return 1;
    }
  } else {
    switch (wannaGrade) {
      case "BRONZE":
        return 1;
      case "BLACK":
        return 3;
      case "SILVER":
        return 9;
      case "GOLD":
        return 27;

      default:
        return 1;
    }
  }
};

const skillItemOnceRunValue = (wannaGrade: string) => {
  //DL = 4 일때만
  const bronzeValue = 2.2;
  const silverValue = 1.97 * 3;
  const goldValue = 0.23 * 9;
  let result = bronzeValue;
  if (wannaGrade === "SILVER") {
    result += silverValue;
  }
  if (wannaGrade === "GOLD") {
    result += silverValue;
    result += goldValue;
  }
  return result;
};

const weaponItemOnceRunValue = (wannaGrade: string) => {
  //DL = 4 일때만
  const bronzeValue = 2.2;
  const blackValue = 2.4 * 3;
  const silverValue = 0.64 * 9;
  const goldValue = 0.07 * 27;
  let result = bronzeValue;
  if (wannaGrade === "BLACK") {
    result += blackValue;
  }
  if (wannaGrade === "SILVER") {
    result += blackValue;
    result += silverValue;
  }
  if (wannaGrade === "GOLD") {
    result += blackValue;
    result += silverValue;
    result += goldValue;
  }
  return result;
};

export const calculation = (props: CalculationProps): CalculateResult => {
  if (props.wannaCounts === undefined) {
    return {
      nowPercent: 100,
      needResin: 0,
    };
  }

  switch (props.type) {
    case "SKILL":
      const targetSkillValue =
        props.wannaCounts *
        setGradeValue({
          type: props.type,
          wannaGrade: props.wannaGrade,
        });
      let currentSkillValue = props.currentItems.BRONZE;

      if (props.wannaGrade === "SILVER") {
        currentSkillValue += props.currentItems.SILVER * 3;
      }
      if (props.wannaGrade === "GOLD") {
        currentSkillValue += props.currentItems.SILVER * 3;
        currentSkillValue += props.currentItems.GOLD * 9;
      }

      const resultSkillPercent = Math.floor(
        (currentSkillValue / targetSkillValue) * 100
      );

      //레진계산
      const restSkillValue = targetSkillValue - currentSkillValue;
      const resultSkillResin = Math.floor(
        (restSkillValue / skillItemOnceRunValue(props.wannaGrade)) * 20
      );

      return {
        nowPercent: resultSkillPercent,
        needResin: resultSkillResin < 0 ? 0 : resultSkillResin,
      };

    case "WEAPON":
      const targetWeaponValue =
        props.wannaCounts *
        setGradeValue({
          type: props.type,
          wannaGrade: props.wannaGrade,
        });
      let currentWeaponValue = props.currentItems.BRONZE;

      if (props.wannaGrade === "BLACK") {
        currentWeaponValue += props.currentItems.BLACK * 3;
      }
      if (props.wannaGrade === "SILVER") {
        currentWeaponValue += props.currentItems.BLACK * 3;
        currentWeaponValue += props.currentItems.SILVER * 9;
      }
      if (props.wannaGrade === "GOLD") {
        currentWeaponValue += props.currentItems.BLACK * 3;
        currentWeaponValue += props.currentItems.SILVER * 9;
        currentWeaponValue += props.currentItems.GOLD * 27;
      }

      const resultWeaponPercent = Math.floor(
        (currentWeaponValue / targetWeaponValue) * 100
      );

      //레진계산
      const restWeaponValue = targetWeaponValue - currentWeaponValue;
      const resultWeaponResin = Math.floor(
        (restWeaponValue / weaponItemOnceRunValue(props.wannaGrade)) * 20
      );

      return {
        nowPercent: resultWeaponPercent,
        needResin: resultWeaponResin < 0 ? 0 : resultWeaponResin,
      };

    default:
      return {
        nowPercent: 100,
        needResin: 0,
      };
  }
};
